'use strict';

import http from '@/utils/http';

export default {
  itemListUrl: '/authority/dictionaryItem/code',
  provinceUrl: '/authority/dictionaryItem/code/tree',
  cityUrl:
    '/authority/dictionaryItem/hierarchyBycode?code=CHINA_AREA&hierarchy=2',
  industryLv1CodeUrl: '/authority/dictionaryItem/code/tree?code=INDUSTRY',
  getList(code, params) {
    return http.get('/issmart-campaign/campaign/page', { params, code });
  },
  add(code, data) {
    return http.post('/authority/applicationClient', data, { code });
  },
  update(code, data) {
    return http.put('/authority/applicationClient', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/applicationClient', { params, code });
  },
  getItemList(code, params) {
    return http.get('/authority/dictionaryItem/code', { params, code });
  },
  getItemTree(code, params) {
    return http.get('/authority/dictionaryItem/code/tree', { params, code });
  },
};
