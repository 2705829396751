<template>
  <!--  <div class="ta-right mr-24">-->
  <!--    <a-button type="primary" @click="visible = true">-->
  <!--      <template #icon><component :is="icons['DatabaseOutlined']" /></template>-->
  <!--      配置-->
  <!--    </a-button>-->
  <!--  </div>-->
  <div class="iss-tool tool-type-list">
    <div v-for="item in tacticList" :key="item.id" class="tool-type-item">
      <div class="type-title">{{ item.tacticName }}</div>
      <a-space direction="vertical" class="type-list">
        <div
          v-for="child in item.children"
          :key="child.id"
          :class="{
            'tool-item': true,
            'item-required': child.required,
            'item-active': !child.required && active.includes(child.id),
          }"
          @click="handleClickByTool(child)"
        >
          <a-avatar :size="36" class="mr-8 iss-avatar">
            <template #icon>
              <component :is="icons[child.icon]" class="fs-20" />
            </template>
          </a-avatar>
          <div class="iss-text">{{ child.tacticName }}</div>
        </div>
      </a-space>
    </div>
  </div>
  <iss-tools
    v-model:visible="visible"
    :scene="scene"
    :tactic="tactic"
    @fnOk="handleFnOk"
  />
</template>

<script>
import { reactive, toRaw, toRefs, watch } from 'vue';
import { Space, Avatar } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import IssTools from './tools';
import activityApi from '@/api/activity';

export default {
  components: {
    ASpace: Space,
    AAvatar: Avatar,
    IssTools,
  },
  props: ['value', 'scene'],
  setup(props, context) {
    const state = reactive({
      tacticList: [],
      active: [],
      visible: false,
      tactic: {},
    });

    const getTacticList = () =>
      activityApi.getTacticList('', { templateId: props.scene }).then(data => {
        let active = [];
        data.forEach(item =>
          item.children.forEach(i => i.required && active.push(i.id))
        );
        state.active = active;
        state.tacticList = data;
      });

    watch(() => props.scene, getTacticList);

    watch(
      () => state.active,
      value => {
        state.tactic.tacticList = value;
        context.emit('update:value', toRaw(state.tactic));
      }
    );

    getTacticList();

    return {
      icons,
      ...toRefs(state),
      handleClickByTool: ({ id, required }) => {
        if (!required) {
          const index = state.active.findIndex(i => i === id);
          index > -1 ? state.active.splice(index, 1) : state.active.push(id);
        }
      },
      handleFnOk: value => {
        const active = value.map(i => parseInt(i.tacticId));
        state.active = [...new Set(active)];
        state.tactic.appList = value;
        context.emit('update:value', toRaw(state.tactic));
      },
    };
  },
};
</script>

<style lang="less" scoped>
.tool-type-list {
  display: flex;
  gap: 10px;
  padding-top: 12px;
  .type-title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    background: #f0f5ff;
    border-radius: 4px 4px 0px 0px;
    //padding: 9px 0px 9px 0px;
    line-height: 40px;
    color: @primary-color;
  }
  .type-list {
    padding: 8px;
    height: calc(100vh - 318px);
    border-radius: 4px;
    //background-color: @primary-2;
    border: 1px solid #e5e6eb;
    margin-bottom: 20px;
    overflow: auto;
    .tool-item {
      padding: 12px;
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;
      transition: all 0.3s;
      border: 1px solid #e5e6eb;
      align-items: center;
      width: 184px;
      display: flex;
      :deep(.ant-avatar) {
        //background: linear-gradient(90deg, @primary-5 0%, @primary-color 100%);
        color: #5979f8;
        background: #f0f5ff;
      }
      &:hover {
        color: @primary-color;
        box-shadow: 0px 2px 6px 0px @primary-4;
      }
      &.item-required,
      &.item-active {
        position: relative;
        color: #5979f8;
        border: 1px solid #5979f8;
        cursor: not-allowed;
        //background: linear-gradient(90deg, @primary-5 0%, @primary-color 100%);
        :deep(.ant-avatar) {
          color: @primary-color;
          background: #f0f5ff;
        }
      }
      &.item-required::before {
        content: '✓';
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        right: -1px;
        top: -1px;
        font-size: 10px;
        color: #fff;
        border-radius: 0px 4px 0px 2px;
        background-color: #5979f8;
      }
      &.item-active::before {
        content: '✓';
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        right: -1px;
        top: -1px;
        font-size: 10px;
        color: #fff;
        border-radius: 0px 4px 0px 2px;
        background-color: #5979f8;
      }
    }
  }
}
.tools-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .tools-item {
    width: 200px;
    background-color: @primary-2;
    border-radius: 4px;
    .item-title {
      padding: 5px 10px;
      font-size: 16px;
      border-bottom: 2px solid @primary-4;
    }
    .item-list {
      padding: 5px 10px;
      :deep(.ant-checkbox-wrapper) {
        display: block;
        margin-bottom: 3px;
        + .ant-checkbox-wrapper {
          margin-left: 0;
        }
      }
    }
  }
}
.iss-tool {
  margin: 0px 20px;
  border-radius: 8px;
  //max-width: calc(100vw - 286px);
  max-width: 100%;
  overflow: auto;
}
.iss-avatar {
  border-radius: 4px;
}
.iss-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 115px;
}
</style>
