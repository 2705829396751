<template>
  <div class="steps-wrapper">
    <div class="steps-title">
      <div
        :class="{
          'title-item': true,
          active: activeTab === 'one',
          after: true,
        }"
      >
        选择任务类型
      </div>
      <div
        :class="{
          'title-item': true,
          active: activeTab === 'two',
          after: true,
        }"
      >
        填写基本信息
      </div>
      <div
        :class="{
          'title-item': true,
          active: activeTab === 'three',
        }"
      >
        选择工具集
      </div>
    </div>
    <div class="steps-content">
      <div v-show="activeTab === 'one'">
        <iss-scene v-model:value="templateId" />
        <div class="mb-20 mt-20 ta-center">
          <a-space>
            <a-button @click="$router.go(-1)">取消</a-button>
            <!-- <a-button @click="$refs.formRef.resetFields()">重置</a-button> -->
            <a-button type="primary" @click="handleClickByStep('two')">
              下一步
            </a-button>
          </a-space>
        </div>
      </div>
      <div v-show="activeTab === 'two'">
        <div class="form-wrap">
          <a-form
            class="iss-form"
            ref="formRef"
            :model="form"
            :rules="rules"
            :colon="false"
            :label-col="{ span: 2 }"
            :wrapper-col="{ span: 8 }"
            :validate-trigger="['change', 'blur']"
          >
            <a-form-item
              label="名称"
              name="campaignName"
              :label-col="{ span: 2 }"
              :wrapper-col="{ span: 7 }"
            >
              <a-input
                v-model:value="form.campaignName"
                autocomplete="off"
                placeholder="请输入名称"
              />
            </a-form-item>
            <a-form-item
              label="日期"
              name="date"
              :label-col="{ span: 2 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-range-picker
                v-model:value="form.date"
                show-time
                style="width: 100%"
              />
            </a-form-item>
            <a-form-item
              label="Banner图"
              name="banner"
              extra="建议上传宽高比为16:9，大小不超过1M，格式为JPG、JPEG或PNG的图片"
            >
              <iss-image-upload
                v-model:value="form.banner"
                list-type="picture-card"
                accept=".jpe,.jpeg,.jpg,.png"
              />
            </a-form-item>
            <a-form-item
              label="国家/省市"
              style="margin-bottom: 0"
              :label-col="{ span: 2 }"
              :wrapper-col="{ span: 15 }"
            >
              <a-row :gutter="10">
                <a-col :span="8">
                  <a-form-item name="country" :wrapper-col="{ span: 24 }">
                    <a-select
                      v-model:value="form.country"
                      show-search
                      placeholder="请选择国家"
                      disabled
                      :filter-option="filterOption"
                      @change="handleChangeByCountry"
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="item in countryList"
                        :key="item.id"
                        :value="item.itemNameCn"
                        :item="item"
                      >
                        {{
                          $store.state.account.language === 'zh-CN'
                            ? item.itemNameCn
                            : item.itemNameEn
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8" v-show="form.country === '中国'">
                  <a-form-item
                    name="province"
                    :wrapper-col="{ span: 24 }"
                    :label-col="{ span: 2 }"
                  >
                    <a-select
                      v-model:value="form.province"
                      allow-clear
                      show-search
                      placeholder="请选择省份"
                      @change="handleChangeByProvince"
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="item in provinceList"
                        :key="item.id"
                        :value="item.itemCode"
                        :item="item"
                      >
                        {{
                          $store.state.account.language === 'zh-CN'
                            ? item.itemNameCn
                            : item.itemNameEn
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8" v-show="form.country === '中国'">
                  <a-form-item
                    name="city"
                    :wrapper-col="{ span: 24 }"
                    :label-col="{ span: 2 }"
                  >
                    <a-select
                      v-model:value="form.city"
                      allow-clear
                      show-search
                      placeholder="请选择城市"
                    >
                      <a-select-option
                        v-for="item in cityList"
                        :key="item.id"
                        :value="item.itemCode"
                      >
                        {{
                          $store.state.account.language === 'zh-CN'
                            ? item.itemNameCn
                            : item.itemNameEn
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item
              label="地址"
              name="location"
              :label-col="{ span: 2 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-input
                v-model:value="form.location"
                autocomplete="off"
                placeholder="请输入地址"
              />
            </a-form-item>
            <a-form-item label="任务目标" name="target">
              <a-radio-group v-model:value="form.target">
                <a-radio :value="1">推广传播</a-radio>
                <a-radio :value="2">留资获客</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              v-if="webSiteUser"
              label="同步数据"
              name="websiteSwitch"
              extra="开启同步数据后，报名参会的联系人数据会同步到阵地用户"
            >
              <a-radio-group v-model:value="form.websiteSwitch">
                <a-radio :value="false">不同步</a-radio>
                <a-radio :value="true">同步</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="目标数量" name="campaignScale">
              <a-row :gutter="10">
                <a-col :span="8">
                  <a-input-number
                    v-model:value="form.campaignScale"
                    style="width: 100%"
                    :min="0"
                    :placeholder="`请输入目标${
                      ['触及', '留资'][form.target - 1]
                    }人数`"
                  />
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item label="预算" name="budget">
              <a-row :gutter="10">
                <a-col :span="8">
                  <a-input-number
                    v-model:value="form.budget"
                    :min="0"
                    placeholder="请输入预算"
                    style="width: 100%"
                  />
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item label="描述" name="campaignDesc">
              <a-textarea
                v-model:value="form.campaignDesc"
                showCount
                :maxlength="100"
                placeholder="请输入描述"
              />
            </a-form-item>
          </a-form>
        </div>

        <div class="ta-center mt-20 mb-20">
          <a-space>
            <a-button @click="activeTab = 'one'">上一步</a-button>
            <!-- <a-button @click="$refs.formRef.resetFields()">重置</a-button> -->
            <a-button type="primary" @click="handleClickByStep('three')">
              下一步
            </a-button>
          </a-space>
        </div>
        <!--        <div class="mr-24 mb-20 ta-right">-->
        <!--          <a-space>-->
        <!--            <a-button @click="activeTab = 'one'">上一步</a-button>-->
        <!--            &lt;!&ndash; <a-button @click="$refs.formRef.resetFields()">重置</a-button> &ndash;&gt;-->
        <!--            <a-button type="primary" @click="handleClickByStep('three')">-->
        <!--              下一步-->
        <!--            </a-button>-->
        <!--          </a-space>-->
        <!--        </div>-->
      </div>
      <div v-show="activeTab === 'three'">
        <iss-tactic v-model:value="tactic" :scene="templateId" />
        <div class="ta-center mt-20 mb-20">
          <a-space>
            <a-button @click="activeTab = 'two'">上一步</a-button>
            <a-button
              type="primary"
              :loading="loading"
              @click="handleClickBySubmit"
            >
              创建活动
            </a-button>
          </a-space>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Col,
  DatePicker,
  Form,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
} from 'ant-design-vue';
import IssImageUpload from '@/components/imageUpload';
import IssScene from './components/scene';
import IssTactic from './components/tactic';
import timeZoneList from '@/dictionaries/timeZone.json';
import activityApi from '@/api/activity';
import dictionaryApi from '@/api/dictionary';
import { ref } from 'vue';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ARangePicker: DatePicker.RangePicker,
    AInputNumber: InputNumber,
    ASpace: Space,
    IssImageUpload,
    IssScene,
    IssTactic,
  },
  data() {
    const type = this.$route.params.type;
    const loading = ref(false);
    const typeList = {
      all: '101', // 全部任务默认线下主办会
      host: '101', // 线下主办会
      exhibition: '102', // 线下参展
      live: '103', // 线上会议
      // 104 Social传播
      thematic: '105', // Website阵地
      socialmedia: '106', // Social阵地
      showroom: '107', // 线上展厅
      common: '108', // 通用任务
    };
    const datacenter = this.$store.state.account.menus.find(
      menu => menu.menuKey === 'datacenter'
    );
    const webSiteUser = datacenter?.children?.find(
      menu => menu.menuKey === 'websiteUser'
    );
    const required = { required: true, whitespace: true, message: '不能为空' };

    return {
      loading,
      typeList,
      timeZoneList,
      webSiteUser,
      type: [type, typeList[type]],
      activeTab: 'one',
      templateList: [],
      languageList: [],
      countryList: [],
      provinceList: [],
      cityList: [],
      tactic: {},
      templateId: typeList[type],
      rules: {
        campaignName: [required, { max: 100, message: '不可超过100字' }],
        location: [{ max: 100, message: '不可超过100字' }],
        date: { ...required, type: 'array' },
        banner: [{ required: true, message: '不能为空', trigger: 'blur' }],
        campaignScale: [
          { ...required, type: 'number' },
          {
            validator: (rule, value, cbfn) => {
              if (Number(value) > 100000000) {
                cbfn('数值过大，请重新输入');
              }
              cbfn();
            },
          },
        ],
        budget: [
          { ...required, type: 'number' },
          {
            validator: (rule, value, cbfn) => {
              if (Number(value) > 100000000) {
                cbfn('数值过大，请重新输入');
              }
              cbfn();
            },
          },
        ],
      },
      form: {
        campaignName: '',
        campaignDesc: '',
        localLanguage: 'Chinese',
        timeZone: '(GMT+08:00) China',
        timeZoneCal: '+08:00',
        date: null,
        target: 1,
        websiteSwitch: false,
        campaignScale: 0,
        budget: 0,
        banner: '',
        location: '',
        country: '中国',
        province: null,
        city: null,
      },
    };
  },
  created() {
    this.getDictionary();
  },
  methods: {
    getDictionary() {
      dictionaryApi
        .getItemList(`${this.type[0]}:add`, { code: 'LOCAL_LANGUAGE' })
        .then(data => (this.languageList = data));
      dictionaryApi
        .getItemTree(`${this.type[0]}:add`, { code: 'AREA' })
        .then(data => (this.countryList = data));
      dictionaryApi
        .getItemTree(`${this.type[0]}:add`, { code: 'CHINA_AREA' })
        .then(data => (this.provinceList = data));
    },
    filterOption(input, { item }) {
      return item.itemNameCn.indexOf(input) >= 0;
    },
    handleClickByStep(next) {
      switch (this.activeTab) {
        case 'one':
          if (
            this.templateId === '101' ||
            this.templateId === '102' ||
            this.templateId === '103' ||
            this.templateId === '106'
          ) {
            this.activeTab = next;
          } else {
            message.error('请选择任务类型');
          }
          break;
        case 'two':
          this.$refs.formRef.validate().then(() => {
            this.activeTab = next;
          });
          break;
        default:
          break;
      }
    },
    handleChangeByCountry() {
      this.form.province = null;
      this.form.city = null;
    },
    handleChangeByProvince(value, option) {
      this.cityList = option ? option.item.children : [];
      this.form.city = null;
    },
    handleClickBySubmit() {
      this.loading = true;
      const date = this.form.date;
      this.form.startTime = date[0].format('YYYY-MM-DD HH:mm:ss');
      this.form.startTimestamp = date[0].toDate().getTime();
      this.form.endTime = date[1].format('YYYY-MM-DD HH:mm:ss');
      this.form.endTimestamp = date[1].toDate().getTime();
      activityApi
        .add(`${this.type[0]}:add`, {
          templateId: this.templateId,
          baseInfo: this.form,
          ...this.tactic,
        })
        .then(({ templateId, ...others }) => {
          setTimeout(() => {
            this.loading = false;
          }, 2000);

          const temp = Object.keys(this.typeList).reduce((acc, key) => {
            acc[this.typeList[key]] = key;
            return acc;
          }, {});
          this.$router.push({
            name: 'activityDetail',
            params: { type: temp[templateId], ...others },
          });
        });
    },
  },
  watch: {
    'form.banner'() {
      this.$refs.formRef.validateFields(['banner']);
    },
  },
};
</script>
<style lang="less" scoped>
.steps-wrapper {
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 16px;
  .steps-content {
    flex: 1;
  }
}
.steps-title {
  display: flex;
  margin: 20px 20px;
  height: 48px;
  line-height: 46px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e5e6eb;
  //box-shadow: 0px 3px 10px 0px @primary-3;
  .title-item {
    flex: 1;
    position: relative;
    //border-radius: 4px 0 0 4px;
    //&.after {
    //  //border-radius: 0 4px 4px 0;
    //  &::after {
    //    display: none;
    //  }
    //}
    &:nth-last-of-type(1){
      border-radius: 0 4px 4px 0;
    }
    &.after::after {
      content: '';
      position: absolute;
      top: 0px;
      right: -19px;
      z-index: 3;
      width: 37.78px;
      height: 46px;
      background: #ffffff;
      clip-path: polygon(0px 50%, 50% 0, 100% 50%, 50% 100%)
      //border-top: 1px solid #e5e6eb;
      //border-right: 1px solid #e5e6eb;
      //transform: rotate(45deg);
      //border: 13px solid transparent;
      //border-color: #fff #fff transparent transparent;
      //box-shadow: 4px -2px 3px @primary-3;
    }
    &.active {
      background: linear-gradient(90deg, @primary-5 0%, @primary-color 100%);
      color: #fff;
      &::after {
        border-color: @primary-color @primary-color transparent transparent;
        background: @primary-color;
        border: 1px solid @primary-color;
        //box-shadow: 2px -1px 4px @primary-color;
      }
    }
  }
}

.form-wrap {
  margin: 20px;
  height: calc(100vh - 268px);
  overflow-y: auto;
}
</style>
