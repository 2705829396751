<template>
  <div class="tmpl-list">
    <div
      v-for="item in templateList"
      :key="item.tempId"
      :class="{
        'tmpl-item': true,
        'item-active': active === item.tempId,
        disabled: item.stauts === '1',
      }"
    >
      <div class="item-wrap" @click="handleClick(item)">
        <div class="item-box">
          <img :src="require(`@/assets/images/activity/${item.tempId}.png`)" />
          <div class="item-title">{{ item.tempName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import activityApi from '@/api/activity';

export default {
  props: ['value'],
  setup(props, context) {
    const state = reactive({
      templateList: [],
      active: props.value,
    });

    activityApi.getTemplateList('').then(data => {
      state.templateList = data;
    });

    return {
      ...toRefs(state),
      handleClick: ({ tempId, stauts }) => {
        if (stauts === '0') {
          state.active = tempId;
          context.emit('update:value', tempId);
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.tmpl-list {
  display: flex;
  flex-wrap: wrap;
  //gap: 16px;
  margin: 12px;
  text-align: center;
  height: calc(100vh - 252px);
  overflow-y: auto;
  .tmpl-item {
    //height: 217px;
    position: relative;
    width: calc(25% - 16px);
    margin: 8px;
    //border: 1px solid #e5e6eb;
    //padding: 20px 30px 10px 30px;
    //background-color: @primary-2;
    //border-radius: 4px;
    //transition: box-shadow 0.3s;
    //cursor: pointer;
    .item-wrap {
      border: 1px solid #e5e6eb;
      border-radius: 4px;
      transition: box-shadow 0.3s;
      cursor: pointer;
      position: relative;
      padding-top: 80%;
      top: 0;
      height: 0;
      width: 100%;
      .item-box {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        img {
          width: 200px;
          height: 160px;
        }
        .item-title {
          margin-top: 5px;
        }
      }
    }
    & .item-wrap:hover {
      box-shadow: 0px 4px 10px 0px #e5e6ebaa;
      background-color: @primary-2;
      border: none;
    }
    &.item-active .item-wrap {
      color: @primary-color;
      background-color: @primary-2;
      border: none;
      box-shadow: 0px 8px 10px 0px #e5e6ebaa;
      //background-color: @primary-color;
      //box-shadow: 0px 0px 10px 0px @primary-5;
    }
    &.disabled {
      background-color: #f1f1f1;
      box-shadow: initial;
      cursor: not-allowed;
    }
  }
}
</style>
