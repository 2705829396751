<template>
  <div class="item-title">
    <a-checkbox
      v-model:checked="checkAll"
      :indeterminate="indeterminate"
      :disabled="item.required"
      @change="handleChangeByAll"
    >
      <span class="fs-16">{{ item.tacticName }}</span>
    </a-checkbox>
  </div>
  <a-checkbox-group v-model:value="checkedList" class="item-list">
    <a-checkbox
      v-for="child in item.appList"
      :key="child.id"
      :value="child.id"
      :disabled="item.required"
    >
      {{ child.appName }}
    </a-checkbox>
  </a-checkbox-group>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import { Checkbox } from 'ant-design-vue';

export default {
  components: {
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
  },
  props: {
    value: { type: Array, default: () => [] },
    item: { type: Object, default: () => ({}) },
  },
  setup(props, context) {
    const state = reactive({
      checkAll:
        props.value.length && props.value.length === props.item.appList.length,
      indeterminate: false,
      checkedList: props.value,
    });

    watch(
      () => props.value,
      value => (state.checkedList = value)
    );
    watch(
      () => state.checkedList,
      value => {
        const appLength = props.item.appList.length;
        state.indeterminate = value.length && value.length < appLength;
        state.checkAll = value.length === appLength;
        context.emit('update:value', value);
      }
    );

    return {
      ...toRefs(state),
      handleChangeByAll: ({ target }) => {
        state.indeterminate = false;
        state.checkedList = target.checked
          ? props.item.appList.map(i => i.id)
          : [];
      },
    };
  },
};
</script>

<style lang="less" scoped>
.item-title {
  padding: 5px 10px;
  font-size: 16px;
  border-bottom: 2px solid @primary-4;
}
.item-list {
  padding: 5px 10px;
  :deep(.ant-checkbox-wrapper) {
    display: block;
    margin-bottom: 3px;
    + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}
</style>
