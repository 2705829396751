<template>
  <a-modal
    :visible="visible"
    title="配置工具集"
    :mask-closable="false"
    :width="902"
    centered
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="tools-list">
      <div v-for="item in toolsList" :key="item.tacticId" class="tools-item">
        <iss-checkbox-group
          v-model:value="active[item.tacticId]"
          :item="item"
        />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import IssCheckboxGroup from './checkboxGroup';
import activityApi from '@/api/activity';

export default {
  components: { IssCheckboxGroup },
  props: ['visible', 'scene', 'tactic', 'tacticList'],
  setup(props, context) {
    const state = reactive({
      toolsList: [],
      active: {},
      initActive: '{}',
    });
    const handleCancel = () => {
      state.active = JSON.parse(state.initActive);
      context.emit('update:visible', false);
    };
    const setTacticList = tacticList => {
      if (tacticList?.length && state.toolsList.length) {
        let active = {};
        tacticList.forEach(i => {
          active[i] =
            state.active[i] ||
            state.toolsList.find(d => d.tacticId === i)?.appList.map(a => a.id);
        });
        state.initActive = JSON.stringify(active);
        state.active = active;
      }
    };
    const getAppList = () =>
      activityApi.getAppList('', { templateId: props.scene }).then(data => {
        state.toolsList = data;
        setTacticList(props.tactic?.tacticList);
      });

    watch(() => props.scene, getAppList);
    watch(
      () => props.tactic,
      ({ tacticList, appList }) => {
        if (
          appList &&
          appList.length !==
            Object.values(JSON.parse(state.initActive)).flat().length
        ) {
          let active = {};
          appList.forEach(i => {
            if (active[i.tacticId]) {
              active[i.tacticId].push(i.appId);
            } else {
              active[i.tacticId] = [i.appId];
            }
          });
          state.initActive = JSON.stringify(active);
          state.active = active;
        } else if (
          tacticList.length !== Object.keys(JSON.parse(state.initActive)).length
        ) {
          setTacticList(tacticList);
        }
      },
      { deep: true }
    );
    watch(
      () => props.tacticList,
      value => {
        let active = {};
        value.forEach(i => {
          active[i.tacticId] = i.appList.map(a => a.id);
        });
        state.initActive = JSON.stringify(active);
        state.active = active;
      }
    );

    props.scene && getAppList();

    return {
      ...toRefs(state),
      handleCancel,
      handleOk: () => {
        let appList = [];
        for (const tacticId in state.active) {
          appList = appList.concat(
            state.active[tacticId].map(appId => ({ tacticId, appId }))
          );
        }
        context.emit('fnOk', appList);
        handleCancel();
      },
    };
  },
};
</script>

<style lang="less" scoped>
.tools-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 400px;
  overflow-y: auto;
  .tools-item {
    width: 200px;
    background-color: @primary-2;
    border-radius: 4px;
  }
}
</style>
